.outer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem;
  p {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
}

.box {
  margin: 0.2rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.photo {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .outer {
    max-width: 100% !important;
  }
}
