.main_content {
  border-top: 0.1em solid #ddd;
  margin-top: 8rem;
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0;
    max-width: 100% !important;
  }
}
