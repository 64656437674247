.main {
  padding-left: 0 !important;
  padding-right: 0 !important;
  // position: relative;
}

.outer {
  padding: 1rem 0 1rem 0;
  border-bottom: 0.1em solid #ddd;
  p {
    margin: 1rem, 0, 1rem, 0;
  }
}

.content {
  img {
    width: 100%;
    height: 100%;
  }
}
.title {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.description {
  padding-top: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .main {
    max-width: 100% !important;
  }
}
