.nav_meta {
  position: fixed;
  padding-left: 10px !important;
  max-width: 210px !important;
}

.nav_meta .MobileNavigation {
  display: none;
}

.logo {
  padding-bottom: 1rem;
  padding-top: 1rem;
  img {
    width: 85px;
  }
  img:hover {
    opacity: 0.5;
  }
}

.menu {
  ul {
    padding-left: 0;
    list-style: none;

    li {
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin-top: 8px;
      margin-bottom: 18px;

      a {
        text-decoration: none;
        color: black;
      }
      a:hover {
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nav_meta {
    max-width: 100% !important;
    background-color: white;
  }

  .nav_meta .Navigation {
    display: none;
  }

  .nav_meta .MobileNavigation {
    display: flex;
    align-items: center;
  }

  .nav_meta .MobileNavigation .hamburger {
    position: absolute;
    right: 7%;
    top: 30%;
    cursor: pointer;
  }

  .nav_meta .MobileNavigation .close_icon {
    position: absolute;
    right: 7%;
    top: 4%;
    cursor: pointer;
  }

  .nav_meta nav ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100vw;
    height: 100vh;
    background-color: white;

    li {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
