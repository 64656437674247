.outer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;

  p {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
}

.embed_responsive {
  max-width: 100%;
  height: 300px;

  margin-bottom: 1rem;

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .outer {
    max-width: 100% !important;
  }
  .embed_responsive {
    max-width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 992px) {
  .embed_responsive {
    height: 350px;
    iframe {
      height: 330px;
    }
  }
}

@media screen and (max-width: 576px) {
  .embed_responsive {
    height: 310px;
    iframe {
      margin-top: 1rem;
      height: 290px;
    }
  }
}
