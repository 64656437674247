.outer {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.video_pack {
  padding: 1rem 0 1rem 0;
  row-gap: 1rem;
  p {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
}
.embed_responsive {
  max-width: 100%;
  height: 700px;
  border-bottom: 0.1em solid #ddd;
  padding-bottom: 2rem;

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .embed_responsive {
    max-width: 100%;
    height: 600px;
  }
}

@media screen and (max-width: 768px) {
  .outer {
    max-width: 100% !important;
  }
  .embed_responsive {
    max-width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 576px) {
  .embed_responsive {
    max-width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 420px) {
  .embed_responsive {
    max-width: 100%;
    height: 200px;
  }
}
